@import "~normalize.css";


.home-container {
  width: 100wv;
}

#page-container {
  position: relative;
  min-height: 100vh;
}

#content-wrap {
  padding-bottom: 4.5rem;    /* Footer height */
  min-height: calc(100vh - 9rem);
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4.5rem;            /* Footer height */
}

#home-text {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

#home-text-container {
  margin:auto;
}

#auth-form {
  margin: 4rem;
  padding: 4rem;
  min-height: 50vh;
}

.menu-link {
  text-decoration: none;
  color: black;
}

.tableRow:nth-child(odd) {
  background-color: #EEEEEE;
}

button.test-action-button {
  margin-right: 1rem;
}

.test-filter-bar {
  padding: 20px;
}

.home-page-image {
  max-width: 100%;
  max-height: 100%;
}